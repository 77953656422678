/**
 * @see {@link https://storylane.atlassian.net/l/cp/iocs2rnX Docs}
 */
export enum DemoPageSearchParams {
  HideAll = 'hide_all',
  HideForms = 'hide_forms',
  HideSteps = 'hide_steps',
  Embed = 'embed',
  Flow = 'flow',
  Token = 'token',
  Scale = 'scale',
  Animation = 'animation',
  AutoPlay = 'auto_play',
  Recording = 'recording',
  Offline = 'offline',
}

export const enum DemoPageEmbedSearchParamValue {
  Inline = 'inline',
  InlineOverlay = 'inline_overlay',
  Popup = 'popup',
}
