import cx from 'classnames';
import {
  ReactNode,
  MutableRefObject,
  FC,
  useState,
  useEffect,
  CSSProperties,
} from 'react';

import styles from './RendererImageFrame.module.css';
import { DisplayConfigRendererBorder } from '../../../features/project/project.types';
import Icon from '../../../components/Icon';
import Tooltip from '../../../components/Tooltip';
import { FullscreenBgColorHex } from './RendererImageFrame.constants';

interface RendererImageFrameProps {
  variant: DisplayConfigRendererBorder;
  children: ReactNode;
  isRendererReady: boolean;
  rendererWrapperRef: MutableRefObject<HTMLDivElement | null>;
  hasShadow?: boolean;
  isScrollable?: boolean;
  showFullScreenButton?: boolean;
  onFullScreenButtonClick?: () => void;
  onVariantChange?: (variant: DisplayConfigRendererBorder) => void;
  isFullscreen?: boolean;
  isResponsive?: boolean;
  bgColor?: string | null;
}

/**
 * Wraps `children` with a browser-like frame.
 * UI purpose only.
 */
const RendererImageFrame: FC<RendererImageFrameProps> = ({
  variant,
  hasShadow = false,
  isScrollable = false,
  isRendererReady = false,
  children,
  onFullScreenButtonClick,
  showFullScreenButton,
  onVariantChange,
  rendererWrapperRef,
  isFullscreen = false,
  isResponsive = false,
  bgColor,
}) => {
  const hasHeader =
    variant !== DisplayConfigRendererBorder.None && !isFullscreen;
  const isLight = variant === DisplayConfigRendererBorder.Light;
  const isDark = variant === DisplayConfigRendererBorder.Dark;

  const [isCSSSupport, setIsCSSSupport] = useState<boolean>(false);

  useEffect(() => {
    setIsCSSSupport(CSS.supports('container-type', 'size'));
  }, []);

  const [slotDimensions, setSlotDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    if (isCSSSupport) {
      return;
    }
    function resizeListener() {
      if (rendererWrapperRef?.current) {
        const { width, height } =
          rendererWrapperRef.current.getBoundingClientRect();
        setSlotDimensions({
          width,
          height,
        });
      }
    }
    if (isRendererReady) {
      resizeListener();
      window.addEventListener('resize', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
  }, [rendererWrapperRef, isRendererReady, isCSSSupport]);

  const finalBgColor = isFullscreen ? FullscreenBgColorHex : bgColor;

  const rendererSlotStyles = {
    background: finalBgColor || undefined,
    '--renderer-slot-height':
      slotDimensions && isRendererReady && !isCSSSupport
        ? `${slotDimensions.height}px`
        : undefined,
    '--renderer-slot-width':
      slotDimensions && isRendererReady && !isCSSSupport
        ? `${slotDimensions.width}px`
        : undefined,
  } as CSSProperties;

  return (
    <div
      className={cx(styles.root, {
        [styles.rootWithHeader]: hasHeader,
        [styles.rootLight]: isLight,
        [styles.rootDark]: isDark,
        [styles.rootHasShadow]: hasShadow,
        [styles.rootScrollable]: isScrollable,
        [styles.rootResponsive]: isResponsive,
      })}
    >
      {hasHeader && (
        <div className={styles.header}>
          <div className={styles.headerCircle} />
          <div className={styles.headerCircle} />
          <div className={styles.headerCircle} />
          <div className={styles.controls}>
            {showFullScreenButton && (
              <Icon
                name="fullscreen"
                className={cx(
                  styles.fullscreenIcon,
                  isDark && styles.fullscreenIconDark
                )}
                onClick={onFullScreenButtonClick}
              />
            )}
            {onVariantChange && (
              <Tooltip triggerOn="hover">
                <Tooltip.Trigger>
                  <button
                    className={cx(styles.mode, {
                      [styles.modeDark]: isDark,
                    })}
                    onClick={() =>
                      onVariantChange(
                        isLight
                          ? DisplayConfigRendererBorder.Dark
                          : DisplayConfigRendererBorder.Light
                      )
                    }
                  >
                    <Icon
                      className={styles.modeIcon}
                      name={isDark ? 'dark-mode' : 'light-mode'}
                    />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Content variant={isDark ? 'dark' : 'light'}>
                  {isDark ? 'Light Mode' : 'Dark Mode'}
                </Tooltip.Content>
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div
        className={styles.frame}
        ref={rendererWrapperRef}
        style={rendererSlotStyles}
      >
        {children}
      </div>
    </div>
  );
};

/**
 * Get frame dimensions. Mainly used to calculate correct image renderer dimensions. Based on css-values.
 * @see packages/shared-components/src/components/renderer/RendererImageFrame/RendererImageFrame.module.css
 *
 * @param frame
 * @returns
 */
export function getRendererImageFrameDimensions(
  frame: DisplayConfigRendererBorder
): {
  top: number;
  bottom: number;
  left: number;
  right: number;
} {
  return {
    top:
      frame === DisplayConfigRendererBorder.None
        ? RendererImageFrameTopBorder
        : RendererImageFrameHeader,
    left: RendererImageFrameLeftBorder,
    right: RendererImageFrameRightBorder,
    bottom: RendererImageFrameBottomBorder,
  };
}

export const RendererImageFrameTopBorder = 1;
export const RendererImageFrameBottomBorder = 1;
export const RendererImageFrameRightBorder = 1;
export const RendererImageFrameLeftBorder = 1;
export const RendererImageFrameHeader = 26;

export default RendererImageFrame;
